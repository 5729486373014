<template>
  <ItemDetails
    :value="value"
    :history="history"
    :overview="overview"
    @edit="handleEdit"
    @input="handleInput"
    @delete="handleDelete"
  >
    <template #title>Workflow Details</template>

    <template #groups="{ value: groups }">
      <ol v-if="groups && groups.length">
        <li v-for="group in groups" :key="group.id">
          {{ group.value }}
        </li>
      </ol>

      <div v-else>-</div>
    </template>
  </ItemDetails>
</template>

<script>
import { workflow } from "@/api/factory.js";
import ItemDetails from "@/components/common/display/item-details/ItemDetails.vue";

export default {
  name: "WorkflowDetails",

  components: { ItemDetails },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      required: true,
    },

    workflow: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      history: [],
    };
  },

  computed: {
    overview() {
      return this.columns.map((column) => ({
        id: column.id,
        name: column.name,
        label: column.label,
        value: this.workflow[column.name],
      }));
    },
  },

  watch: {
    workflow: {
      immediate: true,
      deep: true,
      handler() {
        if (this.workflow.id) {
          this.getWorkflowHistory();
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleEdit() {
      this.$emit("input", false);
      this.$emit("edit", this.workflow.id);
    },

    handleDelete() {
      this.$emit("input", false);
      this.$emit("delete", this.workflow.id);
    },

    /* api functions */

    async getWorkflowHistory() {
      this.isLoading = true;

      const { error, payload } = await workflow.getWorkflowHistory(
        this.workflow.id
      );

      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.history = payload;
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
ol {
  padding-left: 16px;
  margin: 0px;

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
