<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    :mode="mode"
    :show-export="true"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
    @info="handleInfo"
    @export="handleExport"
  >
    <template #status="{ column, value }">
      <div class="row q-px-md q-py-sm">
        <div class="label">{{ column }}</div>

        <q-space />

        <div id="workflow-status" :class="{ draft: value === 'Draft' }">
          <BaseIcon name="mdi-circle" size="8px" inherit-color />

          <div class="q-ml-sm">{{ value }}</div>
        </div>
      </div>
    </template>
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "WorkflowGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleSelect(workflowId) {
      this.$emit("select", workflowId);
    },

    handleEdit(workflowId) {
      this.$emit("edit", workflowId);
    },

    handleDelete(workflowId) {
      this.$emit("delete", workflowId);
    },

    handleRestore(workflowId) {
      this.$emit("restore", workflowId);
    },

    handleMore(workflowId) {
      this.$emit("more", workflowId);
    },

    handleInfo(workflowId) {
      this.$emit("info", workflowId);
    },

    handleExport(formId) {
      this.$emit("export", formId);
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-status {
  display: flex;
  align-items: center;
  color: var(--green);

  &.draft {
    color: var(--orange);
  }
}
</style>
